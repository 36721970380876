function CustomAjaxRequest(params, ajaxMethod, post_url, callback){
    var Ajax = new XMLHttpRequest();
    if(ajaxMethod === 'POST'){
        Ajax.open(ajaxMethod, post_url, true);
        Ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    }else{
        Ajax.open(ajaxMethod, params, true);
    }
    
    Ajax.onreadystatechange = function() {
        if (Ajax.readyState === 4 && Ajax.status === 200) {
            callback(Ajax.responseText);
        }
        if(Ajax.readyState !== 4 && Ajax.status !== 200){
            //CustomAlertMessage('Something went wrong. Please try again later.', 'custom_alert_box', 'Ok', '');
        }
        if(Ajax.status !== 200){
            //CustomAlertMessage('Something went wrong. Please try again later.', 'custom_alert_box', 'Ok', '');
        }
    };
    if(ajaxMethod === 'POST'){
        Ajax.send(params);
    }else{
        Ajax.send();
    }
}

export default CustomAjaxRequest;