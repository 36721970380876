import React, { Component } from 'react';
import { withRouter } from "react-router";
import Menu from './components/Menu';
import CustomTabs from './components/CustomTabs';
import StaticMedia from './components/StaticMedia';
import Footer from './components/Footer';
import CookieBanner from './components/CookieBanner';

class Homepage extends Component {
  componentDidMount(){
    var id_to_scroll = this.props.location.hash.replace('#', '');
    if(id_to_scroll){
      document.getElementById(id_to_scroll).scrollIntoView();
    }
  }
  render() {
    return (
      <div>
        <Menu />
        <CustomTabs />
        <StaticMedia />
        <Footer />
      </div>
    );
  }
}

export default withRouter(Homepage);
