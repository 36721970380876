import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Homepage from './Homepage.js';
import CookieText from './CookieText.js';
import CookieBanner from './components/CookieBanner';
import Videos from './components/Videos';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path='/' render={props =>
            <div>
              <Homepage />
              <CookieBanner />
            </div>
          } />
          <Route path='/cookies' render={props =>
            <div>
              <CookieText />
              <CookieBanner />
            </div>
          } />
          <Route path='/videos' render={props =>
            <div>
              <Videos />
              <CookieBanner />
            </div>
          } />
        </div>
      </Router>
    );
  }
}

export default App;
