import React, { Component } from 'react';

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <div className='center_text footer'>
                <ul id='social_media'>
                    <li><a href='https://www.facebook.com/AQUA.Carpatica/' target='_blank'><img src='images/fb.png' /></a></li>
                    <li><a href='https://twitter.com/aquacarpatica' target='_blank'><img src='images/tw.png' /></a></li>
                    <li><a href='https://www.youtube.com/channel/UCq7gs-yd2_RphvPKjW6_HUQ' target='_blank'><img src='images/yt.png' /></a></li>
                </ul>
                <p id='address'>
                Strada Ion Slătineanu, Nr. 20,<br/>
                Sector 1, București<br/>
                    Phone: +4021.211.14.15 <br/>
                    Email: contact@aquacarpatica.com
                </p>
                <p id='copyright'>
                    Copyright 2018 AQUA Carpatica | All Rights Reserved
                </p>
            </div>
        )
    }
}

export default Footer;