import React, { Component } from 'react';
import Media from "react-media";
import 'rmc-tabs/assets/index.css';
import { Tabs } from 'rmc-tabs';
import {Link} from 'react-router-dom';
import { IoIosMale, IoIosFemale } from 'react-icons/io';
import Calculator from './Calculator.js';
import CountUp from 'react-countup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CustomAjaxRequest from './functionals/CustomAjaxRequest'

class CustomTabs extends Component{
    constructor(props){
        super(props);
        this.state = {
            rezultat_final: '',
            showResult_boy: false,
            showResult_girl: false,
            checked_boy: false,
            checked_girl: false,
            form_is_valid_girl: true,
            form_is_valid_boy: true,
            disabled_button_girl: false,
            disabled_button_boy: false
        }
        this.onUpdate = this.onUpdate.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.showResult = this.showResult.bind(this)
        this.returnResultHTML = this.returnResultHTML.bind(this)
        this.handleFieldValidation = this.handleFieldValidation.bind(this)
    }
    componentDidMount(){
    }
    onUpdate(val){
        if(val.type === 'girl_tab'){
            this.setState({
                rezultat_final: val,
                rezultat_final_girl: val,
                disabled_button_girl: false
            }, function(){
                this.setState({
                    rezultat_final: this.state.rezultat_final_girl
                })
            })
        }
        if(val.type === 'boy_tab'){
            this.setState({
                rezultat_final: val,
                rezultat_final_boy: val,
                disabled_button_boy: false
            }, function(){
            })
        }
        
    }
    handleTabChange(val, k){
        if(k === 0){
            this.setState({
                rezultat_final: this.state.rezultat_final_girl,
            }, function(){
            })
        }else{
            this.setState({
                rezultat_final: this.state.rezultat_final_boy,
            }, function(){
            })
        }
    }
    renderContent() {
        return [
          <div key="1" className='girl_tab' style={{ background: 'transparent' }}>
            <Calculator onUpdate={this.onUpdate} key='1' type='girl_tab' />
          </div>,
          <div key="2" className='boy_tab' style={{ background: 'transparent' }}>
            <Calculator onUpdate={this.onUpdate} key='2' type='boy_tab'/>
          </div>
        ];
    }
    returnResultHTML(html){
        var litri_de_apa_custom = ((this.state.rezultat_final.final_bottles_count * 250) / 1000)
        var litri_de_apa_custom_text = 'litri de apă'
        switch(true){
            case(litri_de_apa_custom < 1):
                litri_de_apa_custom_text = 'litri de apă'
                break;
            case(litri_de_apa_custom === 1):
                litri_de_apa_custom_text = 'litru de apă';
                break;
            case(litri_de_apa_custom > 1):
                litri_de_apa_custom_text = 'litri de apă';
                break;
            default: 

                break;
        }
        return (
        <div className='result_container'>
            <div className='result_col result_left'>
                <div className='advise_text'>
                    <span>{this.state.rezultat_final.name != '' ? this.state.rezultat_final.name : 'Copilul tău '}</span> <span>trebuie să bea zilnic</span>
                </div>
                <div className='bottles_to_drink'>
                    <span className='bottles_count'>
                    {this.state.rezultat_final.final_bottles_count > 0 ? ( 
                        <CountUp start={0} end={(this.state.rezultat_final.final_bottles_count) ? this.state.rezultat_final.final_bottles_count : 0} duration={3} />
                        ) : (
                            <span>0</span>
                        )
                    }
                    </span>
                    <span className='bottles_label'>sticluţe de 250ml</span>
                </div>
            </div>
            <div className='result_col result_right'>
                <ul>
                    {html}
                </ul>
                <p>{litri_de_apa_custom + ' ' + litri_de_apa_custom_text}</p>
            </div>
        </div>
        )
    }
    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    }
    handleValidationByType(checkedTabByType, weight, state_key1, state_key2){
        var form_is_valid = true;
        var form_error_message = '';
        if(checkedTabByType === false ){
            form_is_valid = false;
            form_error_message = 'Trebuie să fii de acord cu termenii și condițiile de utilizare'
        }
        if(weight <= 0){
            form_is_valid = false;
            form_error_message = 'Trebuie să adaugi o greutate validă'
        }
        this.setState({
            [state_key1]: form_is_valid,
            [state_key2]: form_error_message
        })
        return form_is_valid;
    }
    handleFieldValidation(){
        var form_is_valid = true;
        if(this.state.rezultat_final.type === 'boy_tab'){
            form_is_valid = this.handleValidationByType(this.state.checked_boy, this.state.rezultat_final_boy.weight, 'form_is_valid_boy', 'form_error_message_boy')
        }
        if(this.state.rezultat_final.type === 'girl_tab'){
            form_is_valid = this.handleValidationByType(this.state.checked_girl, this.state.rezultat_final_girl.weight, 'form_is_valid_girl', 'form_error_message_girl')
        }
        return form_is_valid;
    }
    showResult(e){
        e.preventDefault();
        var self = this;
        if(this.handleFieldValidation()){
            //do ajax req here
            var state_result = this.state.rezultat_final
            var sex = state_result.type === 'girl_tab' ? 'F' : 'M';
            var age = state_result.age_label;
            var weight = state_result.weight;
            var activity_level = state_result.rating;
            var name = state_result.name;
            CustomAjaxRequest('action=SAVE_DATA&sex=' + sex + '&age=' + age + '&weight=' + weight + '&activity_level=' + activity_level + '&name=' + name, 'POST', 'https://hidratarecorecta.ro/b_e/save.php', function(r){
                var res = JSON.parse(r);
                if(self.state.rezultat_final.type === 'boy_tab'){
                    self.setState({
                        showResult_boy: true,
                        disabled_button_boy: !self.state.disabled_button_boy
                    })
                }
                if(self.state.rezultat_final.type === 'girl_tab'){
                    self.setState({
                        showResult_girl: true,
                        disabled_button_girl: !self.state.disabled_button_girl
                    })
                }
			})
           
        }
    }
    renderTabsBasedOnDevice(tabBarPosition){
        return(
            <Tabs tabs={[
                { title: <div className='i_ate_custom_tab girlTab center_text'><IoIosFemale /><p>Fată</p></div> },
                { title: <div className='i_ate_custom_tab boyTab center_text'><IoIosMale /><p>Băiat</p></div> }
            ]} 
            tabBarPosition={tabBarPosition} 
            tabBarActiveTextColor='#fff' 
            tabBarInactiveTextColor='#6b6b6b' 
            tabBarBackgroundColor='#464646'
            tabBarUnderlineStyle={{border: 'none'}}
            onChange={this.handleTabChange}
            >
                {this.renderContent()}
            </Tabs>
        )
    }
    renderCheckboxBasedOnTab(checked_type, onChangeParamORvalue, checkboxColor, id, activeCheckboxClass, disabledButtonValue, formIsValid, formErrorMessage){
        return(
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked_type}
                            onChange={this.handleCheckboxChange(onChangeParamORvalue)}
                            value={onChangeParamORvalue}
                            color={checkboxColor}
                            id={id}
                            className={checked_type ? activeCheckboxClass : ''}
                        />
                    }
                    label={(<label htmlFor={id}>
                    Sunt de acord cu stocarea și folosirea datelor mele personale furnizate în această pagină pentru afișarea unui rezultat de calcul. Pentru mai multe detalii poți consulta <Link to='cookies'>politica de confidențialitate</Link>
                    </label>)}
                />
                <p className='see_answer_button_container'><Button disabled={disabledButtonValue} variant="contained" color="primary" className='see_answer' onClick={this.showResult}>Vezi răspuns</Button></p> 
                {formIsValid === false ? (<p className='client_validation_error'>{formErrorMessage}</p>) : ('')}
            </div>
        )
    }
    showResultBasedOnType(tab_type, html, showResultType){
        return ((tab_type === 'girl_tab' && this.state.disabled_button_girl === true) || (tab_type === 'boy_tab' && this.state.disabled_button_boy === true)) &&(
            <div>
                {showResultType === true && this.state.rezultat_final.type === tab_type ? (
                    this.returnResultHTML(html)
                ) : ( 
                    ''
                )}
            </div>
        )
    }
    render(){
        var bottles_count = this.state.rezultat_final.final_bottles_count;
        var html = [];
            for(var i = 0; i < bottles_count; i++){
                html.push(<li key={i}><img src='images/aqua_bottle.png' /></li>)
            }
        return(
            <div id='tabs' name='CalculatorSection'>
                    <h1 className='center_text green_headline'>Tu știi câtă apă trebuie să bea copilul tău?</h1>
                    <div className='custom_tabs_container'>
                        <img src='images/happy_girl.png' id='absolute_girl' />
                        <Media query={{ maxWidth: 640 }}>
                        {matches =>
                            matches ? (
                                this.renderTabsBasedOnDevice('top')
                            ) : (
                                this.renderTabsBasedOnDevice('left')
                            )
                        }
                        </Media>
                    </div>

                    {this.showResultBasedOnType(this.state.rezultat_final.type, html, (this.state.rezultat_final.type === 'boy_tab' ? this.state.showResult_boy : this.state.showResult_girl))}

                    {this.state.rezultat_final.type === 'boy_tab' ? (
                        this.renderCheckboxBasedOnTab(this.state.checked_boy, 'checked_boy', 'primary', 'chbx_boy', 'boy_check_active', this.state.disabled_button_boy, this.state.form_is_valid_boy, this.state.form_error_message_boy)
                    ) : ('')}

                    {this.state.rezultat_final.type === 'girl_tab' ? (
                            this.renderCheckboxBasedOnTab(this.state.checked_girl, 'checked_girl', 'secondary', 'chbx_girl', 'girl_check_active', this.state.disabled_button_girl, this.state.form_is_valid_girl, this.state.form_error_message_girl)
                        ) : ('')
                    }
                    
                    <p id='disclaimer'>
                    Formula de calcul a cantităţii optime de hidratare este bazată pe studiul <a href='EFSAJournal.pdf' target='_blank'>EFSA Journal</a> şi metoda <a href='HollidaySegarMethod.pdf' target='_blank'>Holliday-Segar</a> împreună cu recomandările elaborate de colectivul de pediatrii de la Asociaţia Română pentru Educaţie Pediatrică în Medicina de Familie. (AREPMF).
                    </p>
            </div>
        )
    }
}

export default CustomTabs;