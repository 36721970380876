import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Media from "react-media";
import Sticky from 'react-sticky-el';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
        }
        this.scrollTo = this.scrollTo.bind(this)
    }
    componentDidMount() {
        var self = this;
        Events.scrollEvent.register('begin', function (to, element) {
        });
        Events.scrollEvent.register('end', function (to, element) {
            self.setState({
                isTop: Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) <= 50 ? true : false
            })
        });
        window.addEventListener('scroll', function(){
            self.setState({
                isTop: Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) <= 50 ? true : false
            })
        });
        scrollSpy.update();
    }
    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }
    scrollTo(event, item) {
        var self = this;
        //console.log(event, item)
        scroller.scrollTo(item, {
            duration: 500,
            offset: 0,
            smooth: true,
            isTop: Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) <= 50 ? true : false
        });
    }
    scrollToTop() {
        scroll.scrollToTop();
    }
    handleSetActive(to) {
    }
    handleSetInactive(to){
    }
    render() {
        return (
            <div name='HomeSection'>
                <Media query={{ maxWidth: 640 }}>
                    {matches =>
                        matches ? (
                            <div>
                                <div id='logo_wrapper'><img id='logo' src='images/logo.png' /></div>
                                <ul id='main_menu'>
                                    <li><Link onClick={this.scrollToTop} to="HomeSection">Home</Link></li>
                                    <li><Link spy={true} onClick={(event) => this.scrollTo(event, 'ContactSection')} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive}>Contact</Link></li>
                                    <li><a activeClass="active" href='#/videos'>Video</a></li>
                                    <li><Link spy={true} onClick={(event) => this.scrollTo(event, 'CalculatorSection')} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive}>Calculator</Link></li>
                                </ul>
                            </div>
                        ) : (
                                <Sticky>
                                    <ul id='main_menu'>
                                        <li><Link className={this.state.isTop === true ? 'active' : ''} activeClass="active" to="HomeSection" onClick={this.scrollToTop} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive}>Home</Link></li>
                                        <li><Link activeClass="active" to="ContactSection" spy={true} onClick={(event) => this.scrollTo(event, 'ContactSection')} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive}>Contact</Link></li>
                                        <li><Link activeClass="active" to="#"><img id='logo' src='images/logo.png'></img></Link></li>
                                        <li><a activeClass="active" href='#/videos'>Video</a></li>
                                        <li><Link activeClass="active" to="CalculatorSection" spy={true} onClick={(event) => this.scrollTo(event, 'CalculatorSection')} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive}>Calculator</Link></li>
                                    </ul>
                                </Sticky>
                            )
                    }
                </Media>
            </div>
        )
    }
}

export default Menu;