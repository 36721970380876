import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import YouTube from 'react-youtube';
import StaticMenu from './StaticMenu';
import Footer from './Footer';
var videos = [{
    id: 'RauIZZCwavI',
    title: 'Playouth - Minutul de Chimie susținut de AQUA Carpatica Kids',
    borderColor: '#00a6ff'
},]
class Videos extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
        this.handleCookieConsent = this.handleCookieConsent.bind(this)
    }
    componentDidMount(){

    }
    handleCookieConsent(e){
        e.preventDefault();
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.stopVideo()
    }
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: { // https://developers.google.com/youtube/player_parameters
              autoplay: 1
            }
          };
        return(
            <div className='cookie_text wrapper'>
                <div className='cookie_header'>
                    <StaticMenu />
                </div>
                <div className='cookie_text_container'>
                    {videos.map((video, index) => {
                        return (
                            <div id={'youtube_video_container' + index} className='center_text' name='ContactSection'>
                                <h1 className='center_text green_headline green_headline_long'>{video.title}</h1>
                                <div className='youtube_container ytb_container_with_border ' style={{'border-color': video.borderColor}}>
                                    <div className='videoWrapper'>
                                        <YouTube
                                            videoId={video.id}
                                            opts={opts}
                                            onReady={this._onReady}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>
                <Footer />
            </div>
        )
    }
}

export default Videos;