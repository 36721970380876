import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Zoom from '@material-ui/core/Zoom';

class CookieBanner extends Component{
    constructor(props){
        super(props);
        this.state = {
            show_cookie_banner: true
        }
        this.handleCookieConsent = this.handleCookieConsent.bind(this)
    }
    componentDidMount(){

    }
    handleCookieConsent(e){
        e.preventDefault();
        this.setState({show_cookie_banner: false})
    }
    render(){
        return(
            <div>
                <Zoom in={this.state.show_cookie_banner}>
                    <div className='cookie_banner' id='cookie_banner'>
                        <p>
                            Acest site folosește cookie-uri. Continuând să utilizezi acest website, ești de acord cu stocarea tuturor cookie-urilor pe acest device. 
                        </p>
                        <p>
                            <Link to='/' onClick={this.handleCookieConsent} id='cookie_agree_btn'>Sunt de acord</Link> 
                            <Link to='/cookies' id='cookie_details_btn'>Mai multe detalii</Link>
                        </p>
                    </div>
                </Zoom>
            </div>
        )
    }
}

export default CookieBanner;