import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import Media from "react-media";
import Sticky from 'react-sticky-el';

class StaticMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
        }
    }
    render() {
        return (
            <div name='HomeSection'>
                <Media query={{ maxWidth: 640 }}>
                    {matches =>
                        matches ? (
                            <div>
                                <div id='logo_wrapper'><img id='logo' src='images/logo.png' /></div>
                                <ul id='main_menu'>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to='/contact_section'>Contact</Link></li>
                                    <li><Link className='active' activeClass="active" href='#/videos'>Video</Link></li>
                                    <li><Link to='/#tabs'>Calculator</Link></li>
                                </ul>
                            </div>
                        ) : (
                                <Sticky>
                                    <ul id='main_menu'>
                                        <li><Link activeClass="active" to="/" >Home</Link></li>
                                        <li><Link activeClass="active" to='/#contact_section'>Contact</Link></li>
                                        <li><Link activeClass="active" to="#"><img id='logo' src='images/logo.png'></img></Link></li>
                                        <li><Link className='active' activeClass="active" to='#/videos'>Video</Link></li>
                                        <li><Link activeClass="active" to="/#tabs" >Calculator</Link></li>
                                    </ul>
                                </Sticky>
                            )
                    }
                </Media>
            </div>
        )
    }
}

export default withRouter(StaticMenu);