import React, { Component } from 'react';
import Slider from 'react-rangeslider'
import NumericInput from 'react-numeric-input';
import 'react-rangeslider/lib/index.css';
import ReactStars from 'react-stars'

function addKg(value){
    return value + ' kg'
}
function parse(stringValue) {
    return stringValue.replace(/kg/ig, " ");
}

class Calculator extends Component {
    constructor(props) {
        super(props);
        var initial_type = this.props.type;
        this.state = {
            [this.props.type]: {rating_has_been_changed: false, final_bottles_count: 0, age: initial_type === 'girl_tab' ? 5 : 7, weight: initial_type === 'girl_tab' ? 0 : 0, rating: initial_type === 'girl_tab' ? 0 : 0, name: '', rezultat: 0, age_label: 0 },
            type: initial_type,
            kid_image: ''
        }
        this.ratingChanged = this.ratingChanged.bind(this)
        this.handleWeightChange = this.handleWeightChange.bind(this)
        this.handleAgeChange = this.handleAgeChange.bind(this)
        this.handleAgeChangeStart = this.handleAgeChangeStart.bind(this)
        this.handleAgeChangeComplete = this.handleAgeChangeComplete.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.calculeaza = this.calculeaza.bind(this)
        this.setAgeLabel = this.setAgeLabel.bind(this)
    }
    componentDidMount() {
        this.calculeaza();
    }
    handleAgeChangeStart() {
        //console.log('Change event started')
    }
    handleAgeChange(value) {
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                age: value
            })
        }, function () {
            this.setState({
                [this.state.type]: Object.assign({}, this.state[this.state.type], {
                    poate_modifica_activitate: this.state[this.state.type]['age'] < 5 ? false : true
                })
            }, function () {
                this.props.onUpdate(this.state[this.state.type]);
                this.calculeaza();
            })
        })
    }
    handleAgeChangeComplete() {
        this.calculeaza();
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                poate_modifica_activitate: this.state[this.state.type]['age'] < 5 ? false : true
            })
        }, function () {
        })
    }
    ratingChanged(newRating) {
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                rating: newRating,
                rating_has_been_changed: true
            })
        }, function () {
            this.props.onUpdate(this.state[this.state.type]);
            this.calculeaza();
        })
    }
    handleWeightChange(weight) {
        if(weight != null && weight > 0){
            this.setState({
                [this.state.type]: Object.assign({}, this.state[this.state.type], {
                    weight: weight
                })
            }, function () {
                this.props.onUpdate(this.state[this.state.type]);
                this.calculeaza();
            })
        }
    }
    handleNameChange(e){
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                name: e.target.value
            })
        }, function () {
            this.props.onUpdate(this.state[this.state.type]);
        })
    }
    calculeaza(){
        var original_data = this.state[this.state.type];
        var data = this.state[this.state.type];
        var water_based_on_age_start = 0;
        var selected_age = data.age;
        var selected_weight = data.weight;
        var selected_activity = this.state[this.state.type].poate_modifica_activitate === false ? 0 : data.rating;
        var kid_image = '';
        console.log(this.state[this.state.type].poate_modifica_activitate)
        console.log(selected_activity)
        switch(selected_age){
            case 1:
                //console.log('child is at most 6 months old');
                water_based_on_age_start = 0.1/0.19;
                kid_image = '1.jpg'
                break;
            case 2:
                water_based_on_age_start = 0.9;
                kid_image = '1.jpg'
               // console.log('child age is between 6 months and 1 year old');
                break;
            case 3:
                water_based_on_age_start = ((1.3 + 1.15) / 2).toFixed(2);
                kid_image = '2.jpg'
               // console.log('child age is between 1 year and 2 years old');
                break;
            case 4:
                //console.log('child age is between 2 year and 3 years old');
                water_based_on_age_start = 1.3;
                kid_image = '3.jpg'
                break;
            case 5:
                water_based_on_age_start = 1.65;
                kid_image = '4.jpg'
                //console.log('child age is between 4 year and 8 year old');
                break;
            case 6:
                if(this.state.type === 'girl_tab'){
                    water_based_on_age_start = 2;
                }else{
                    water_based_on_age_start = ((2.4 + 2.1) / 2).toFixed(2)
                }
                kid_image = '5.jpg'
                //console.log('child age is between 9 year and 13 year old');
                break;
            case 7:
                if(this.state.type === 'girl_tab'){
                    water_based_on_age_start = ((2.3 + 2) / 2).toFixed(2);
                }else{
                    water_based_on_age_start = ((3.3 + 2.5) / 2).toFixed(2)
                }
                kid_image = '6.jpg'
                //console.log('child age is between 14 year and 18 year old');
                break;
            default: 
                //console.log('child age unknown')
                kid_image = '1.jpg'
                break;
        }
        this.setState({
            kid_image: kid_image
        })
        var array = [];
        for(var i = 0; i <= selected_weight; i++){
            array.push(i)
        }
        var i,j,temparray,chunk = 10;
        var final_chunks = [];
        for (i=1,j=array.length; i<j; i+=chunk) {
            temparray = array.slice(i,i+chunk);
            // do whatever
            final_chunks.push(temparray);
        }
        var urmatorii_10_idx = 0;
        if(final_chunks[1]){
            urmatorii_10_idx = final_chunks[1].map(function (item) { return item; }).indexOf(final_chunks[1].pop());
        }
        if(final_chunks[0]){
            var primii_10_temp = final_chunks[0].pop();
        }
        var urmatorii_10_temp = final_chunks[1] ? (urmatorii_10_idx + 1) : 0;

        var primii_10 = primii_10_temp * 100;
        var urmatorii_10 = urmatorii_10_temp * 50;
        var kg_ramase = (selected_weight > 0) ? ((selected_weight - 20) * 20) : 0;
            kg_ramase = kg_ramase >= 0 ? kg_ramase : 0;

        var litri_apa_based_on_weight = (primii_10 + urmatorii_10 + kg_ramase) / 1000;

        var medie_litri = (parseFloat(water_based_on_age_start) + litri_apa_based_on_weight) / 2
        var medie_cu_procent = 80/100 * medie_litri;

        var niveluri_activitate = [0, 0.175, 0.25, 0.5];
        var nivel_de_activitate_selectat = niveluri_activitate[selected_activity - 1];

        var nivel_de_activitate_selectat = 0;
        switch(selected_activity){
            case 1:
                nivel_de_activitate_selectat = -0.25;
                break;
            case 2:
                nivel_de_activitate_selectat = -0.175;
                break;
            case 3: 
                nivel_de_activitate_selectat = 0;
                break;
            case 4:
                nivel_de_activitate_selectat = 0.25
                break;
            case 5:
                nivel_de_activitate_selectat = 0.5
                break;
            default:

                break;

        }
        var l_cu_activitate = medie_cu_procent + (nivel_de_activitate_selectat);
        var final_result = l_cu_activitate / 0.25;
        var decimal_vals = Math.round((final_result - Math.floor(final_result)) * 100) / 100;
        switch(true){
            case (decimal_vals < 0.25):
                final_result = parseInt(final_result);
                //console.log('mai putin de 0.25')
                break;
            case (decimal_vals < 0.75):
                final_result = parseInt(final_result) + 1;
                //console.log('intre 0.25 si 0.75')
                break;
            case (decimal_vals >= 0.75):
                final_result = parseInt(final_result) + 1;
                //console.log('mai mult de 0.75')
                break;
            default:

                break;
        }
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                final_bottles_count: final_result,
                type: this.state.type
            })
        }, function () {
            this.props.onUpdate(this.state[this.state.type]);
            this.setAgeLabel();
        })
        
    }
    setAgeLabel(){
        var age_label = 0;
        switch(this.state[this.state.type]['age']){
            case 1:
                //console.log('child is at most 6 months old');
                age_label = '0-6 luni'
                break;
            case 2:
               // console.log('child age is between 6 months and 1 year old');
               age_label = '6-12 luni'
                break;
            case 3:
               // console.log('child age is between 1 year and 2 years old');
               age_label = '1-2 ani'
                break;
            case 4:
                //console.log('child age is between 2 year and 3 years old');
                age_label = '2-3 ani'
                break;
            case 5:
                //console.log('child age is between 4 year and 8 year old');
                age_label = '4-8 ani'
                break;
            case 6:
                //console.log('child age is between 9 year and 13 year old');
                age_label = '9-13 ani'
                break;
            case 7:
                //console.log('child age is between 14 year and 18 year old');
                age_label = '14-18 ani'
                break;
            default: 
                //console.log('child age unknown')
                break;
        }
        this.setState({
            [this.state.type]: Object.assign({}, this.state[this.state.type], {
                age_label: age_label
            })
        }, function () {
            this.props.onUpdate(this.state[this.state.type]);
        })
    }
    render() {
        var self = this;
        
        var labels_activitate = ['foarte sedentar', 'sedentar', 'mediu', 'activ', 'foarte activ']
        return (
            <div>
                    <div className='calc_rows'>
                        <div className='calc_row'>
                            <div className='calc_label'>
                                Vârsta
                            </div>
                            <div className='calc_field calc_field_age'>
                                <div className='calc_sub_field calc_sub_field_slider'>
                                    <Slider
                                        min={2}
                                        max={7}
                                        step={1}
                                        value={this.state[this.state.type]['age']}
                                        onChangeStart={this.handleAgeChangeStart}
                                        onChange={this.handleAgeChange}
                                        onChangeComplete={this.handleAgeChangeComplete.bind(this.state[this.state.type]['age'])}
                                        tooltip={false}
                                    />
                                </div>
                                <div className='calc_sub_field calc_sub_field_age'>
                                    <div className='age'>
                                        {this.state[this.state.type].age_label}
                                    </div>
                                    <div className='age_picture' style={{ background: 'transparent url(images/' + this.state.kid_image + ') no-repeat center / cover' }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='calc_row'>
                            <div className='calc_label'>
                                Greutate
                            </div>
                            <div className='calc_field'>
                                <NumericInput inputMode='numeric' mobile={true} min={1} max={100} onChange={this.handleWeightChange} value={this.state[this.state.type].weight} style={{
                                    wrap: {
                                        borderRadius: '25px'
                                    },
                                    input: {
                                        fontWeight: 500,
                                        padding: '0',
                                        outline: 'none',
                                        fontSize: '20px'
                                    },
                                    'input:focus': {
                                        outline: 'none'
                                    }
                                }}
                                step={1}
                                precision={0}
                                maxLength={2}
                                />
                            </div>
                        </div>
                            <div className='calc_row'>
                                <div className='calc_label'>
                                    Activitate
                                </div>
                                {/*<div className={'calc_field ' + (this.state[this.state.type].poate_modifica_activitate === false ? 'inactive_stars' : 'active_stars')}> */}
                                <div className={'calc_field active_stars'}>
                                    {/*{this.state[this.state.type].poate_modifica_activitate === false ? (<div className='inactive_stars_overlay'></div>) : ('')}*/}
                                    <ReactStars
                                    className='rating_stars'
                                    count={5}
                                    onChange={this.ratingChanged}
                                    size={50}
                                    value={this.state[this.state.type].rating}
                                    half={false}
                                    color2={'#f07743'} />
                                    {this.state[this.state.type].rating_has_been_changed === true ? (
                                        <ul id='rating_label'>
                                            <li>{labels_activitate[this.state[this.state.type].rating - 1]}</li>
                                        </ul>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            </div>
                            
                        <div className='calc_row'>
                            <div className='calc_label'>
                                Nume
                            </div>
                            <div className='calc_field'>
                                <input type='text' name='nume' id='nume' onFocus={(e) => e.target.placeholder = ""} 
  onBlur={(e) => e.target.placeholder = this.state.type == 'girl_tab' ? 'Andreea' : 'Andrei'} placeholder={this.state.type == 'girl_tab' ? 'Andreea' : 'Andrei'} onChange={this.handleNameChange} value={this.state[this.state.type].name} />
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
        }
        
export default Calculator;