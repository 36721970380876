import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class CookieText extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
        this.handleCookieConsent = this.handleCookieConsent.bind(this)
    }
    componentDidMount(){

    }
    handleCookieConsent(e){
        e.preventDefault();

    }
    renderUnsafeHTML(){
        return {__html:
            `<div>
            <p>Acord privind prelucrarea datelor cu caracter personal</p>
<p>Deoarece asigurarea protecției dvs. în ceea ce privește prelucrare datelor cu caracter personal, reprezintă pentru noi un obiectiv extrem de important, am depus toate diligentele necesare pentru a ne conforma standardelor impuse prin Regulamentul UE 2016/679 (<a href="https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri=CELEX:32016R0679">https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri=CELEX:32016R0679</a>) si prin orice alt act normativ în vigoare pe teritoriul României<span class="Apple-converted-space">&nbsp;</span></p>
<p>Un pas important pentru realizarea acestui obiectiv este reprezentat de informarea în legătura cu modul în care datele dumneavoastra vor fi prelucrate( prelucrare înseamnă orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziție în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea).</p>
<p>Ne rezervăm dreptul de a modifica și actualiza Politica de Confidențialitate și Politica de Cookie.</p>
<h3>1. Cine suntem și modul în care putem fi contactați:</h3>
<p style="padding-left: 30px;"><i>Strada Ion Slătineanu, Nr. 20,<u></u><u></u></i></p>
<p style="padding-left: 30px;"><i>Sector 1, București<u></u><u></u></i></p>
<p style="padding-left: 30px;"><i>Phone: +4021.211.14.15</i></p>
<p style="padding-left: 30px;"><i><u></u><u></u></i><i>Email:&nbsp;<a href="mailto:contact@aquacarpatica.com" target="_blank" rel="noopener"><span class="il">contact</span>@aquacarpatica.<wbr>com</a></i></p>
<h3>2. Colectare date/ tipuri de date</h3>
<ol>
<li>Introduse de dvs (aveți deplin control)
<ol>
<li>Numele, (categoria de varsta), sexul(baiat/fata), greutate, activitate;</li>
</ol>
</li>
</ol>
<p style="padding-left: 120px;">Mențiune:<span class="Apple-converted-space">&nbsp;</span></p>
<ol>
<li style="list-style-type: none;">
<ol>
<li style="list-style-type: none;">
<ol>
<li style="list-style-type: none;">
<ol>
<li style="list-style-type: none;">
<ol>
<li>Parintele, tutorele ori persoana in ingrijirea ori supravegherea careia se afla minorul ce nu a implinit varsta de 16 ani, isi da acordul in vederea prelucrarii datelor cu caracter personal ale acestuia , ca titular al raspunderii parintesti asupra copilului.</li>
</ol>
</li>
</ol>
</li>
</ol>
</li>
</ol>
</li>
</ol>
<ol start="2">
<li>Colectate automat in urma accesării(de tipul)
<ul>
<li>Adresă IP</li>
<li>Data și ora accesului</li>
<li>Diferența de fus orar față de Meridianul Greenwich (GMT)</li>
<li>Conținutul cererii (website specific)</li>
<li>Starea accesului/codul de stare HTTP</li>
<li>Volumul de date transferat</li>
<li>Acces solicitat la website</li>
<li>Browser, setări de limbă, versiunea de browser, sistem de operare și suprafață</li>
</ul>
</li>
</ol>
<h3>3. Fișierele cookie</h3>
<h4>1. Ce sunt?</h4>
<ol>
<li>Acest Website folosește așa-numitele „fișiere cookie”. Fișierele cookie sunt fișiere text de mici dimensiuni care sunt stocate în memoria terminalului dumneavoastră prin intermediul browser-ului. Acestea stochează anumite informații (de ex. limba preferată sau setările website-ului) pe care browser-ul le poate retransmite nouă (în funcție de durata de viață a fișierului cookie) la următoarea vizită pe Website-ul nostru.</li>
</ol>
<h4>2. Ce fișiere cookie folosim?</h4>
<ol>
<li>Nume: PHPSESSID<span class="Apple-converted-space">&nbsp;</span>
<ol>
<li>Tip – sesiune</li>
<li>Scop: masuri de siguranta la trimiterea de formulare catre server</li>
</ol>
</li>
<li>Cookies Google Analytics</li>
<li>Cookies Facebook</li>
<li>Nume: user_pref
<ol>
<li style="list-style-type: none">
<ol>
<li>Durata – 180 zile</li>
<li>Scop: Utilizat pentru a păstra preferintele utilizatorului</li>
</ol>
</li>
</ol>
</li>
</ol>
<h4>3. Cookie-uri de analiza site</h4>
<ol>
<li>În cadrul Website-ului nostru, utilizăm Google Analytics, un serviciu de analiză al Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, Statele Unite (La care se va face in continuare referinta sub denumirea „Google”).
<ol>
<li>Google va analiza utilizarea Website-ului nostru în numele nostru. În acest scop folosim fișierele cookie. Informațiile colectate de Google în legătură cu utilizarea de către dumneavoastră a Website-ului nostru (de ex. URL aferent, paginile noastre web vizitate de dumneavoastră, tipul dumneavoastră de browser, setările dumneavoastră de limbă, sistemul dumneavoastră de operare, rezoluția ecranului, etc.) vor fi transmise într-un server al Google din SUA, unde vor fi stocate și analizate. Rezultatele respective vor fi apoi puse la dispoziția noastră într-o formă pseudonimizată. De asemenea, Google respecta <a href="https://www.privacyshield.gov/participant?ID=a2zt000000001L5AAI">Scutul de confidențialitate UE-SUA</a>, care asigură menținerea unui nivel adecvat de protecție în legătură cu prelucrarea datelor de către Google în SUA.</li>
<li>Vă puteți retrage consimțământul privind utilizarea analizei web în orice moment, descărcând și instalând <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">Plug-in-ul pentru Browser Google</a> furnizat.</li>
<li>Mai multe informații cu privire la Google Analytics sunt disponibile în <a href="https://www.google.com/analytics/terms/us.html">Termenii de utilizare Google Analytics</a>, în <a href="https://support.google.com/analytics/answer/6004245?hl=en-GB">Orientările privind protecția datelor și confidențialitate ale Google Analytics</a> și în <a href="http://www.google.com/intl/en/policies/privacy">Politica de confidențialitate Google</a>.</li>
</ol>
</li>
<li>În cadrul Website-ului nostru, utilizăm Floodlight, un serviciu de analiză Google.
<ol>
<li>Acest seriviciu ne ajuta pe noi sa urmarim conversiile (actiunile utilizatorilor care au ajuns pe site prin intermediul unei reclame Google) campaniilor de marketing desfasurate.</li>
<li>Mai multe informații cu privire la Floodlight si cum sa va retrageti consimtamantul sunt disponibile <a href="&quot;https://support.google.com/dcm/answer/2839090”">aici</a> sau <a href="“https://www.wikihow.com/Disable-Cookies”">aici</a>.</li>
</ol>
</li>
<li>În cadrul Website-ului nostru, utilizăm Facebook Pixel, un serviciu de analiză Facebook, Inc. Menlo Park, California. (La care se va face in continuare referinta sub denumirea „Facebook”).
<ol>
<li>Acest seriviciu ne ajuta pe noi sa urmarim rata de succes a campaniilor de marketing desfasurate in platforma <a href="http://facebook.com">facebook.com</a> ;<span class="Apple-converted-space">&nbsp; </span>Facebook respecta <a href="https://www.facebook.com/about/privacyshield">Scutul de confidentialitate UE-USA</a></li>
<li>Mai multe informații cu privire la Facebook Pixel si cum sa va retrageti consimtamantul sunt disponibile <a href="https://www.facebook.com/policies/cookies/">aici</a>.</li>
</ol>
</li>
</ol>
<h3>4. Scopul prelucrării datelor</h3>
<ol>
<li>Pentru uz intern
<ol>
<li>Putem utiliza informaţiile respective pentru generarea de rapoarte si prezentari interne.</li>
</ol>
</li>
<li>Pentru marketing
<ol>
<li>Putem utiliza informaţiile respective pentru îmbunătăţirea produselor şi serviciilor pe care vi le oferim.</li>
</ol>
</li>
<li>Pentru apărarea intereselor noastre legitime
<ol>
<li>Pentru apărarea intereselor noastre legitime (În cazul unor situații excepționale precum atacuri cibernetice care pot periclita siguranța platformei noastre sau în cazul în care informațiile sunt solicitate de către instituțiile publice competente în vederea soluționării anchetelor în desfășurare, ne rezervăm dreptul de prelucra informațiile vizate)</li>
</ol>
</li>
</ol>
<h3>5. Cât timp păstrăm datele dvs. cu caracter personal</h3>
<p style="padding-left: 30px;">Durata de timp pentru care vor fi păstrate datele dvs. este limitată și va fi determinate de perioada necesară îndeplinirii scopurilor în care sunt prelucrate datele, perioada ce nu poate depași data de 31.12.2019.</p>
<h3>6. Terți<span class="Apple-converted-space">&nbsp;</span></h3>
<p style="padding-left: 30px;">A: Google Analytics</p>
<ol>
<li style="list-style-type: none;">
<ol>
<li>Scop: Web analytics</li>
<li>Politici: <a href="https://www.google.com/analytics/terms/gb.html">https://www.google.com/analytics/terms/gb.html</a>, <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245<span class="Apple-converted-space">&nbsp;</span></a></li>
</ol>
</li>
</ol>
<p style="padding-left: 30px;">B: Facebook</p>
<ol>
<li style="list-style-type: none;">
<ol>
<li>Scop: Marketing</li>
<li>Politica: <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a><span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</li>
</ol>
<p style="padding-left: 60px;">Mențiune</p>
<ol>
<li style="list-style-type: none;">
<ol>
<li style="list-style-type: none;">
<ol>
<li>În cazul în care ne revine obligația legală sau dacă este necesar pentru a ne apăra un interes legitim, putem de asemenea divulga anumite date cu caracter personal unor autorități publice.</li>
<li>Ne asigurăm că accesul la datele dvs. de către terții persoane juridice de drept privat se realizeaza în conformitate cu prevederile legale privind protecția datelor si confidențialitatea informațiilor, în baza unor contracte încheiate cu aceștia.</li>
</ol>
</li>
</ol>
</li>
</ol>
<h3>7. Teritorialitate:<span class="Apple-converted-space">&nbsp;</span></h3>
<p style="padding-left: 30px;">Prelucrarea datelor se face doar pe teritoriul Romaniei.<span class="Apple-converted-space">&nbsp;</span><b>Cu exceptia </b>datelor pseudonimizate folosite in functia de analiza site descrisa la pct. 3. 3 “Cookie-uri de analiza site” care se face in SUA conform procedurii descrise si este reglementata de <a href="http://www.dataprotection.ro/?page=Scutul_de_confidentialitate_UE-SUA&amp;lang=ro">Scutul de confidentialitate SUA-UE</a></p>
<h3>8. Drepturile Dvs</h3>
<h4>Dreptul de acces al persoanei vizate</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Persoana vizată are dreptul de a obține din partea operatorului o confirmare că se prelucrează sau nu date cu caracter personal care o privesc și, în caz afirmativ, acces la datele respective și la următoarele informații:</p>
<ul>
<li style="list-style-type: none;">
<ol start="1">
<li>scopurile prelucrării;</li>
<li>categoriile de date cu caracter personal vizate;</li>
<li>destinatarii sau categoriile de destinatari cărora datele cu caracter personal le-au fost sau urmează să le fie divulgate, în special destinatari din țări terțe sau organizații internaționale;</li>
<li>acolo unde este posibil, perioada pentru care se preconizează că vor fi stocate datele cu caracter personal sau, dacă acest lucru nu este posibil, criteriile utilizate pentru a stabili această perioadă;<span class="Apple-converted-space">&nbsp; </span></li>
<li>existența dreptului de a solicita operatorului rectificarea sau ștergerea datelor cu caracter personal ori restricționarea prelucrării datelor cu caracter personal referitoare la persoana vizată sau a dreptului de a se opune prelucrării;<span class="Apple-converted-space">&nbsp;</span></li>
<li>dreptul de a depune o plângere în fața unei autorități de supraveghere;</li>
</ol>
</li>
</ul>
<h4>Dreptul la rectificare</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Persoana vizată are dreptul de a obține de la operator, fără întârzieri nejustificate, rectificarea datelor cu caracter personal inexacte care o privesc. Ținându-se seama de scopurile în care au fost prelucrate datele, persoana vizată are dreptul de a obține completarea datelor cu caracter personal care sunt incomplete, inclusiv prin furnizarea unei declarații suplimentare.</p>
<h4>Dreptul la ștergerea datelor („dreptul de a fi uitat”)</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Persoana vizată are dreptul de a obține din partea operatorului ștergerea datelor cu caracter personal care o privesc, fără întârzieri nejustificate, iar operatorul are obligația de a șterge datele cu caracter personal fără întârzieri nejustificate în cazul în care se aplică unul dintre următoarele motive:</p>
<p style="padding-left: 60px;">(a) datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate;</p>
<p style="padding-left: 60px;">(b) persoana vizată își retrage consimțământul pe baza căruia are loc prelucrarea, în conformitate cu articolul 6 alineatul (1) litera (a) sau cu articolul 9 alineatul (2) litera (a), și nu există niciun alt temei juridic pentru prelucrarea;</p>
<p style="padding-left: 60px;">(c) persoana vizată se opune prelucrării în temeiul articolului 21 alineatul (1) și nu există motive legitime care să prevaleze în ceea ce privește prelucrarea sau persoana vizată se opune prelucrării în temeiul articolului 21 alineatul (2);</p>
<p style="padding-left: 60px;">(d) datele cu caracter personal au fost prelucrate ilegal;</p>
<p style="padding-left: 60px;">(e) datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care revine operatorului în temeiul dreptului Uniunii sau al dreptului intern sub incidența căruia se află operatorul;</p>
<p style="padding-left: 60px;">(f) datele cu caracter personal au fost colectate în legătură cu oferirea de servicii ale societății informaționale menționate la articolul 8 alineatul (1).</p>
<p style="padding-left: 30px;">(2) <span class="Apple-converted-space">&nbsp; </span>În cazul în care operatorul a făcut publice datele cu caracter personal și este obligat, în temeiul alineatului (1), să le șteargă, operatorul, ținând seama de tehnologia disponibilă și de costul implementării, ia măsuri rezonabile, inclusiv măsuri tehnice, pentru a informa operatorii care prelucrează datele cu caracter personal că persoana vizată a solicitat ștergerea de către acești operatori a oricăror linkuri către datele respective sau a oricăror copii sau reproduceri ale acestor date cu caracter personal.</p>
<p style="padding-left: 30px;">(3) <span class="Apple-converted-space">&nbsp; </span>Alineatele (1) și (2a) nu se aplică în măsura în care prelucrarea este necesară:</p>
<p style="padding-left: 60px;">(a) pentru exercitarea dreptului la liberă exprimare și la informare;</p>
<p style="padding-left: 60px;">(b) pentru respectarea unei obligații legale care prevede prelucrarea în temeiul dreptului Uniunii sau al dreptului intern care se aplică operatorului sau pentru îndeplinirea unei sarcini executate în interes public sau în cadrul exercitării unei autorități oficiale cu care este învestit operatorul;</p>
<p style="padding-left: 60px;">(c) din motive de interes public în domeniul sănătății publice, în conformitate cu articolul 9 alineatul (2) literele (h) și (i) și cu articolul 9 alineatul (3);</p>
<p style="padding-left: 60px;">(d) în scopuri de arhivare în interes public, în scopuri de cercetare științifică sau istorică ori în scopuri statistice, în conformitate cu articolul 89 alineatul (1), în măsura în care dreptul menționat la alineatul (1) este susceptibil să facă imposibilă sau să afecteze în mod grav realizarea obiectivelor prelucrării respective; sau</p>
<p style="padding-left: 60px;">(e) pentru constatarea, exercitarea sau apărarea unui drept în instanță.</p>
<h4>Dreptul la restricționarea prelucrării</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Persoana vizată are dreptul de a obține din partea operatorului restricționarea prelucrării în cazul în care se aplică unul din următoarele cazuri:</p>
<p style="padding-left: 60px;">(a) persoana vizată contestă exactitatea datelor, pentru o perioadă care îi permite operatorului să verifice exactitatea datelor;</p>
<p style="padding-left: 60px;">(b) prelucrarea este ilegală, iar persoana vizată se opune ștergerii datelor cu caracter personal, solicitând în schimb restricționarea utilizării lor;</p>
<p style="padding-left: 60px;">(c) operatorul nu mai are nevoie de datele cu caracter personal în scopul prelucrării, dar persoana vizată i le solicită pentru constatarea, exercitarea sau apărarea unui drept în instanță; sau</p>
<p style="padding-left: 60px;">(d) persoana vizată s-a opus prelucrării în conformitate cu articolul 21 alineatul (1), pentru intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează asupra celor ale persoanei vizate.</p>
<p style="padding-left: 30px;">(2) <span class="Apple-converted-space">&nbsp; </span>În cazul în care prelucrarea a fost restricționată în temeiul alineatului (1), astfel de date cu caracter personal pot, cu excepția stocării, să fie prelucrate numai cu consimțământul persoanei vizate sau pentru constatarea, exercitarea sau apărarea unui drept în instanță sau pentru protecția drepturilor unei alte persoane fizice sau juridice sau din motive de interes public important al Uniunii sau al unui stat membru.</p>
<p style="padding-left: 30px;">(3) <span class="Apple-converted-space">&nbsp; </span>O persoană vizată care a obținut restricționarea prelucrării în temeiul alineatului (1) este informată de către operator înainte de ridicarea restricției de prelucrare.</p>
<h4>Dreptul la portabilitatea datelor</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Persoana vizată are dreptul de a primi datele cu caracter personal care o privesc și pe care le-a furnizat operatorului într-un format structurat, utilizat în mod curent și care poate fi citit automat și are dreptul de a transmite aceste date altui operator, fără obstacole din partea operatorului căruia i-au fost furnizate datele cu caracter personal, în cazul în care:</p>
<p style="padding-left: 60px;">(a) prelucrarea se bazează pe consimțământ în temeiul articolului 6 alineatul (1) litera (a) sau al articolului 9 alineatul (2) litera (a) sau pe un contract în temeiul articolului 6 alineatul (1) litera (b); și</p>
<p style="padding-left: 60px;">(b) prelucrarea este efectuată prin mijloace automate.</p>
<p style="padding-left: 30px;">(2) <span class="Apple-converted-space">&nbsp; </span>În exercitarea dreptului său la portabilitatea datelor în temeiul alineatului (1), persoana vizată are dreptul ca datele cu caracter personal să fie transmise direct de la un operator la altul acolo unde acest lucru este fezabil din punct de vedere tehnic.</p>
<p style="padding-left: 30px;">(3) <span class="Apple-converted-space">&nbsp; </span>Exercitarea dreptului menționat la alineatul (1) din prezentul articol nu aduce atingere articolului 17. Respectivul drept nu se aplică prelucrării necesare pentru îndeplinirea unei sarcini executate în interes public sau în cadrul exercitării unei autorități oficiale cu care este învestit operatorul.</p>
<p style="padding-left: 30px;">(4) <span class="Apple-converted-space">&nbsp; </span>Dreptul menționat la alineatul (1) nu aduce atingere drepturilor și libertăților altora.</p>
<h4>Dreptul de a fi notificat în legătura cu rectificarea, ștergerea sau restricționarea prelucrării datelor cu caracter personal</h4>
<p style="padding-left: 30px;">(1) <span class="Apple-converted-space">&nbsp; </span>Operatorul comunică fiecărui destinatar căruia i-au fost divulgate datele cu caracter personal orice rectificare sau ștergere a datelor cu caracter personal sau restricționare a prelucrării efectuate în conformitate cu articolul 16, articolul 17 alineatul (1) și articolul 18, cu excepția cazului în care acest lucru se dovedește imposibil sau presupune eforturi disproporționate. Operatorul informează persoana vizată cu privire la destinatarii respectivi dacă persoana vizată solicită acest lucru.</p>
        </div>`
                                            }
    }
    render(){
        return(
            <div className='cookie_text wrapper'>
                <div className='cookie_header'>
                    <p>
                        <Link activeClass="active" to="/"><img id='logo' src='images/logo.png'></img></Link>
                    </p>
                    <h1 className='center_text green_headline'>Politică de confidențialitate</h1>
                </div>
                <div className='cookie_text_container' dangerouslySetInnerHTML={this.renderUnsafeHTML()}></div>
            </div>
        )
    }
}

export default CookieText;