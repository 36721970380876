import React, { Component } from 'react';
import YouTube from 'react-youtube';

class StaticMedia extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.stopVideo()
    }
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: { // https://developers.google.com/youtube/player_parameters
              autoplay: 1
            }
          };
        return(
            <div>
                <div id='static_bottles_container' className='center_text'>
                    <h1 className='blue_headline'>Sticluța AQUA Carpatica Kids din cel mai potrivit izvor AQUA pentru copii, Băjenaru*</h1>
                    <p><img id='static_bottles' src='images/static_bottles.png' /></p>
                    <p className='disclaimer water_disclaimer'>
                    *Izvoarele AQUA Carpatica: Băjenaru, Haja, Ichim, Păltiniș, AQUA 2 și AQUA 3 își au originea în adâncurile munților Carpați, într-o zonă protejată unde nu se desfășoară activități agricole sau industriale.
Toate izvoarele noastre de apă minerală naturală necarbogazoasă (Băjenaru, Haja și Ichim) au calități excepționale și un gust pur, iar proprietățile lor sunt similare. Însă, din grijă pentru cei mai mici consumatori ai noștri, am ales să îmbuteliem AQUA Carpatica Kids din izvorul Băjenaru, izvorul cu cel mai mic conținut de sodiu (0,63) și de nitrați (0,80).
                    </p>
                </div>
                <div id='contact_section' className='center_text' name='ContactSection'>
                    <h1 className='pink_headline'>Să bea apă singur: 
una dintre micile mari victorii ale copilăriei. </h1>
                    <div className='youtube_container'>
                        <div className='videoWrapper'>
                            <YouTube
                                videoId="tIWl3I1xA_w"
                                opts={opts}
                                onReady={this._onReady}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StaticMedia;